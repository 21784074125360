import React from 'react'
import {motion} from 'framer-motion'
import { fadeInOut } from '../animations'

const RecommendedTimeInput = ({type, inputState, inputStateFunc}) => {
  return (
    <motion.div 
      {...fadeInOut}
      className={`flex items-center justify-center gap-2 bg-lightOverlay backdrop-blur-md w-52 px-4 py-2 border-2 rounded-lg`}>
          <input 
          type={type}
          value={inputState}
        onChange={(e) => inputStateFunc(e.target.value)}
          className='w-full h-full bg-transparent text-headingColor text-md font-semibold border-none outline-none [-moz-appearance:_textfield] [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none'
           />
      </motion.div>
  )
}

export default RecommendedTimeInput
