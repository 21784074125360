import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { FaEdit, FaTrash } from 'react-icons/fa'

const ApprovedListTable = () => {

  const [ approved, setApproved ] = useState([]);
 
  useEffect(() => {
    const fetchata = async () => {
  
        const response = await fetch(
          'https://62.12.113.73/api.aaadev.com/api/questions/approved');
           const data = await response.json();
 
           //use only 3 sample data
           setApproved( data )
       
    }
  
    // Call the function
    fetchata();
 }, []);



  return (
    <div class="flex flex-col">
  <div class="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
    <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
      <div class="overflow-hidden">
        <table class="min-w-full">
          <thead class="bg-gray-200 border-b">
            <tr>
              <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                Question ID
              </th>

              <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                Question No.
              </th>

              <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                Curriculum
              </th>

              <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                Exam Year
              </th>

              <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                Exam Paper
              </th>

              <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                Total Marks
              </th>

              <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                Recommended Time
              </th>

              <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
          {
             approved.map( (approve,key) =>
            <tr key={key} class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{approve.question_id}</td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{approve.question_number}</td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{approve.curriculum}</td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{approve.exam_year}</td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{approve.exam_paper}</td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{approve.total_marks}</td>
              <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{approve.recommended_time}</td>
              <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
              <span class='flex gap-4'>
                   <FaTrash />
                   <FaEdit/>
            </span>
              </td> 
            </tr>
             )
          }
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
    // <div>
    //   <table class='w-full'>
    //     <thead class='border-b-2 border-gray-200'>
    //         <tr>
    //             <th class='p-1 flex'>Permission</th>
    //             <th class='p-1'>Action</th>
    //         </tr>
    //     </thead>

    //     <tbody>
    //     {
    //      permissions.map( (permission,key) =>
    //      <tr key={key}>
    //       <td class='p-2 pt-2 font-light text-sm'>{permission.permission}</td>
    //       <td class='p-2 pt-2 font-light text-sm'></td>
          
          

    //       <td>
    //           <span class='flex items-center justify-center gap-4'>
    //               <FaTrash />
    //               <FaEdit/>
    //           </span>
    //       </td>
    //      </tr>
    //      )
    //    }
            
  
    //     </tbody>
    //   </table>
    // </div>
  )
}

export default ApprovedListTable
