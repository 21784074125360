import React from 'react'
import { UserManagementTabs } from '../components'

const UserManagement = () => {
  return (
    <div className='flex flex-col'>
      <UserManagementTabs/>
    </div>
  )
}

export default UserManagement
