import React, { useState } from 'react'
import { LoginBg, Logo } from '../assets'
import { LoginInput } from '../components'
import { FaEnvelope, FaLock } from 'react-icons/fa'
import { FcGoogle } from 'react-icons/fc'
import { motion } from 'framer-motion'
import { buttonClick } from '../animations';
import { NavLink } from 'react-router-dom';
import { alertInfo } from '../context/actions/alertActions'
import { useDispatch } from 'react-redux'
import {useNavigate} from 'react-router-dom'
import axios from 'axios';
import { toast } from 'react-hot-toast'

const Login = () => {

const [userEmail, setUserEmail] = useState("");
const [password, setPassword] = useState("");

const navigate = useNavigate();
    const dispatch = useDispatch();

const login = async () => {
    const data = {email :userEmail, password: password}
   
    try {
        const response = await fetch(`https://62.12.113.73/api.aaadev.com/api/login`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        console.log(response.body);
        if(response.status === 201){
            toast.success("Login Successfully")
            navigate("/dashboard/home", {replace : true})
        }
    } catch (error) {
        console.log(error);
    }
   
}



  return (
    <div className='w-screen h-screen overflow-hidden relative flex'>

        {/* background img */}
        <img src={LoginBg} alt='BgImg' className='w-full h-full absolute object-cover top-0 left-0' />

        {/* content box */}
        <div className='flex flex-col items-center bg-lightOverlay w-[80%] md:w-375 h-full z-10 backdrop-blur-md p-4 px-4 py-4 gap-4'>
            {/* logo section */}
            <div className='flex items-center gap-2 py-6'>
        <p className='font-semibold text-2xl text-[#c23b21]'><span className='text-[#ffd301]'>Funda</span>Manje</p>
        <p className='text-white'>|</p>
        <p className="text-white text-xs">Making Studying Smarter</p>
        </div>
        {/* welcome section */}
        <p className='text-headingColor text-2xl font-semibold'>Welcome Back</p>
        <p className='text-textColor -mt-4'>Sign in with the following</p>

        {/* input section */}
        <div className='flex flex-col items-center w-full justify-center gap-6 px-4 md:px-12 py-2'>
            <LoginInput
                placeHolder="Enter Email"
                icon={<FaEnvelope className='text-textColor'/>}
                inputState={userEmail}
                inputStateFunc={setUserEmail}
                type="email"
            />

            <LoginInput
                placeHolder="Enter Password"
                icon={<FaLock className='text-textColor '/>}
                inputState={password}
                inputStateFunc={setPassword}
                type="password"
            />

            

            
                {/* <p>Add New Admin? <motion.button 
                {...buttonClick}
                onClick={() => navigate("/create_account", {replace : true})}
                className='text-red-500 underline cursor-pointer bg-transparent'>
                Create one
                </motion.button></p> */}

            {/* button */}
          
          <motion.button 
            {...buttonClick} 
            onClick={() => login()}
            className='w-full px-4 py-2 bg-[#c23b21]  rounded-md cursor-pointer text-white capitalize hover:bg-red-600 transition-all duration-150'
            >Sign In</motion.button>
          
        </div>

        <div className='flex items-center justify-between gap-16'>
            <div className='w-24 h-[1px] bg-white rounded-md'></div>
            <p className='text-white'>or</p>
            <div className='w-24 h-[1px] bg-white rounded-md'></div>
        </div>

        <motion.div {...buttonClick} 
        className='flex items-center justify-center px-10 py-2 bg-yellow-400 backdrop-blur-md cursor-pointer rounded-3xl gap-4'>
            <FcGoogle className='text-3xl'/>
            <p className='capitalize text-base text-black'>Sign In with Google</p>
        </motion.div>
        </div>
        </div>
    
  )
}

export default Login