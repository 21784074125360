import React, { useState } from 'react'
import { LoginBg } from '../assets'
import { LoginInput } from '../components'
import { FaEnvelope, FaLock } from 'react-icons/fa'
import { FcGoogle } from 'react-icons/fc'
import { motion } from 'framer-motion'
import { buttonClick } from '../animations';
import { NavLink } from 'react-router-dom';
import { alertInfo } from '../context/actions/alertActions'
import { useDispatch } from 'react-redux'
import {useNavigate} from 'react-router-dom'
import axios from 'axios';
import { toast } from 'react-hot-toast'

const SignUp = () => {

const [firstName, setFirstName] = useState("");
const [lastName, setLastName] = useState("");
const [userName, setUserName] = useState("");
const [phoneNumber, setPhoneNumber] = useState("");
const [userEmail, setUserEmail] = useState("");
const [accessLevel, setAccessLevel] = useState("");
const [password, setPassword] = useState("");


const navigate = useNavigate();
    const dispatch = useDispatch();

const signUp = async () => {
    const data = {
        first_name: firstName,
        last_name: lastName,
        username: userName,
        phone_number: phoneNumber,
        email: userEmail,
        access_level: accessLevel,
        password: password
    }

    try {
        const response = await fetch(`https://62.12.113.73/api.aaadev.com/api/create-account`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if(response.status === 201){
            toast.success("Account created successfully! Proceed to Login");
            navigate("/login", {replace : true})
            
            // window.location.reload('https://62.12.113.73/dashboard/exams');
            
        }
    }catch (error) {
        console.log(error)
    }
   
}



  return (
    <div className='w-screen h-screen overflow-hidden relative'>

        {/* background img */}
        <img src={LoginBg} alt='BgImg' className='w-full h-full absolute object-cover top-0 left-0' />

        {/* content box */}
        <div className='flex flex-col items-center justify-center bg-lightOverlay w-[80%] md:w-375 h-full z-10 backdrop-blur-md p-4 px-4 py-4 gap-4'>
            {/* logo section
            <div className='flex items-center gap-2 py-6'>
        <p className='font-semibold text-2xl text-[#c23b21]'><span className='text-[#ffd301]'>Funda</span>Manje</p>
        <p className='text-white'>|</p>
        <p className="text-white text-xs">Making Studying Smarter</p>
        </div> */}
        {/* welcome section */}
        <p className='text-black text-2xl font-semibold'>Sign Up</p>

        {/* input section */}
        <div className='flex flex-col items-center w-full justify-center gap-4 px-4 md:px-12'>
            <LoginInput
                placeHolder="First Name"
                inputState={firstName}
                inputStateFunc={setFirstName}
                type="text"
            />

            <LoginInput
                placeHolder="Last Name"
                inputState={lastName}
                inputStateFunc={setLastName}
                type="text"
            />

            <LoginInput
                placeHolder="Username"
                inputState={userName}
                inputStateFunc={setUserName}
                type="text"
            />

            <LoginInput
                placeHolder="Phone Number"
                inputState={phoneNumber}
                inputStateFunc={setPhoneNumber}
                type="number"
            />

            <LoginInput
                placeHolder="Enter your email"
                inputState={userEmail}
                inputStateFunc={setUserEmail}
                type="email"
            />

            <LoginInput
                placeHolder="Access Level"
                inputState={accessLevel}
                inputStateFunc={setAccessLevel}
                type="text"
            />

            <LoginInput
                placeHolder="Enter Password"
                inputState={password}
                inputStateFunc={setPassword}
                type="password"
            />

            {/* button */}
        
            <motion.button 
            {...buttonClick} 
            onClick={() => signUp()}
            className='w-full px-4 py-2 bg-[#c23b21]  rounded-md cursor-pointer text-white capitalize hover:bg-red-600 transition-all duration-150'
            >Register</motion.button>

                <p>Already have Account? <motion.button 
                {...buttonClick}
                onClick={() => navigate("/login", {replace : true})}
                className='text-red-500 underline cursor-pointer bg-transparent'>
                Sign In
                </motion.button></p>

        </div>
        </div>
        </div>
    
  )
}

export default SignUp;