import React from 'react'
import { MdSearch, MdLogout, BsToggles2, BsFillBellFill } from '../assets/icons'
import { useSelector } from 'react-redux'
import {motion} from 'framer-motion'
import { buttonClick } from '../animations'
import { Avatar } from '../assets'
import {useNavigate} from 'react-router-dom'
import { toast } from 'react-hot-toast'
// import { getAuth } from 'firebase/auth'
// import { app } from '../config/firebaseConfig'
// import { useNavigate } from 'react-router-dom'
// import { setUserNull } from '../context/actions/userActions'

const DBHeader = () => {

  const navigate = useNavigate();

    const user = useSelector((state => state.user));
    // const firebaseAuth = getAuth(app);
    // const navigate = useNavigate();
    // const dispatch = useDispatch();

    const signOut = async () => {

      try {
        const response = await fetch(`https://62.12.113.73/api.aaadev.com/api/logout`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });
        // console.log(response);
        if(response.status === 201){
            toast.success("Logged out Successfully")
            navigate("/login", {replace : true})
        }
      } catch (error) {
        console.log(error)
      }
        // firebaseAuth.signOut().then(() => {
        //     dispatch(setUserNull());
        //     navigate("/login", {replace : true});
        // })
        // .catch((err) => console.log(err));
    }
  return (
    <div className='w-full flex items-center justify-between gap-3 mb-10'>

    <p className='text-xl text-headingColor'>Welcome <span className='font-semibold text-blue-500'>User</span>
    {user?.name && <span className='block text-base text-gray-500'>{`Hello ${user?.name}!`}</span>}
    </p>

    <div className="flex items-center justify-center gap-4">

    <div className='flex items-center justify-center gap-3 px-4 py-2 bg-lightOverlay backdrop-blur-md rounded-md shadow-md'>
      <MdSearch className="text-gray-400 text-lg"/>
        <input type='text' placeholder='Search here...' className='border-none outline-none bg-transparent w-32 text-base font-semibold text-textColor'/>
        <BsToggles2 className="text-gray-400 text-lg"/>
    </div>

    <motion.div {...buttonClick} className="w-10 h-10 rounded-md cursor-pointer bg-lightOverlay backdrop-blur-md shadow-md flex items-center justify-center">
      <BsFillBellFill className="text-gray-400 text-lg" />
    </motion.div>

    <div className="flex items-center justify-center gap-2">

      <div className="w-10 h-10 rounded-md shadow-md cursor-pointer overflow-hidden">
        <motion.img className="w-full h-full object-cover" src={"https://62.12.113.73/admin.aaadev.com/static/media/avatar.68cdc4513e601d28a382.png"} whileHover={{ scale: 1.15 }} referrerPolicy="no-referrer"/>
      </div>

      <motion.div onClick={signOut} {...buttonClick} className="w-10 h-10 rounded-md cursor-pointer bg-lightOverlay backdrop-blur-md shadow-md flex items-center justify-center">
        <MdLogout className="text-gray-400 text-lg" />
      </motion.div>
    </div>

    </div>
    
    </div>
  )
}

export default DBHeader