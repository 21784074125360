import React from "react";
import { Routes, Route } from "react-router-dom";
import { Dashboard, Login, SignUp } from "./containers";
import { Toaster } from "react-hot-toast"
import { GlobalProvider } from "./state/globalState";



function App() {
  return (
    <div className="w-screen min-h-screen h-auto flex flex-col items-center justify-center">
    <Toaster position="top-right" toastOptions={{ duration: 3000 }} />
    <GlobalProvider>
    <Routes>
      <Route path="/*" element={<Login/>}/>
      <Route path="/create_account/*" element={<SignUp/>}/>
      <Route path="/dashboard/*" element={<Dashboard/>}/>
    </Routes>
    </GlobalProvider>
    </div>
  );
}

export default App;
