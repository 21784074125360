import React, { useState } from 'react'
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa'
import { CChart } from '@coreui/react-chartjs'


const SubjectTab = () => {

  const topics = [
    {
      id: 1,
      topic: 'Functions and Interverse Functions'
    },

    {
      id: 2,
      topic: 'Exponential and Logarithmic Functions'
    },

    {
      id: 3,
      topic: 'Finance, Growth and Decay'
    },

    {
      id: 4,
      topic: 'Trigonometry: Compound and Double Angle Identities'
    },

    {
      id: 5,
      topic: 'Trigonometry: Problem Solving in Two and Three Dimensions'
    },

    {
      id: 6,
      topic: 'Polynomials'
    },

    {
      id: 7,
      topic: 'Differential Calculus'
    },

    {
      id: 8,
      topic: 'Analytical Geometry'
    },

    {
      id: 9,
      topic: 'Euclidean Geometry'
    },

    {
      id: 10,
      topic: 'Statistics'
    },

    {
      id: 11,
      topic: 'Counting Principles and Probability'
    },

  ];

  const [topicData, setTopicData] = useState(topics[1].topic);

  const nextTopic = () => {
    
  }

  return (
    <div className='flex flex-col gap-4'>

    <div className='flex items-center justify-center gap-10 mb-4'>
        <FaChevronCircleLeft size={24} className='text-gray-400'/>
        <p className='font-light'>{topicData}</p>
        <FaChevronCircleRight size={24} className='text-gray-400'/>
    </div>
      
        <div className='flex justify-between gap-4'>

          <div className='flex flex-col bg-white px-6 py-4 shadow-lg rounded-md gap-4 w-[50%]'>
    
            <CChart
  type="line" 
  data={{
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "My First dataset",
        backgroundColor: "rgba(220, 220, 220, 0.2)",
        borderColor: "rgba(220, 220, 220, 1)",
        pointBackgroundColor: "rgba(220, 220, 220, 1)",
        pointBorderColor: "#fff",
        data: [40, 20, 12, 39, 10, 40, 39, 80, 40]
      },
      {
        label: "My Second dataset",
        backgroundColor: "rgba(151, 187, 205, 0.2)",
        borderColor: "rgba(151, 187, 205, 1)",
        pointBackgroundColor: "#076BF7",
        pointBorderColor: "#fff",
        data: [50, 12, 28, 29, 7, 25, 12, 70, 60]
      },
    ],
  }}/>

  <p className='font-light'>Number of questions per topic area per year</p>
          </div>

          <div className='flex flex-col bg-white px-6 py-4 shadow-lg rounded-md gap-4 w-[50%]'>
    
            <CChart
  type="bar" 
  data={{
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'GitHub Commits',
        backgroundColor: '#076BF7',
        data: [40, 20, 12, 39, 10, 40, 39, 80, 40],
      },
    ],
  }}/>

  <p className='font-light'>Number of questions per topic area per year per paper</p>
          </div>
        </div>


        <div className='flex justify-between gap-4'>
        <div className='flex-col bg-white px-6 py-4 shadow-lg rounded-md w-[50%]'>

        <div className='w-72 h-64'>
        <CChart
        className='w-full h-full'
        type="doughnut"
        data={{
        labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
        datasets: [
        {
        backgroundColor: ['#41B883', '#E46651', '#076BF7', '#DD1B16'],
        data: [40, 20, 80, 10],
        },
        ],
        }}/>
        </div>


        <p className='font-light mt-16'>Topic area with percentage attempted questions</p>
         </div>

         <div className='flex-1 flex-col bg-white px-6 py-4 shadow-lg rounded-md'>

        <div className='w-72 h-64'>
        <CChart 
  type="radar"
  data={{
    labels: [
      'Eating',
      'Drinking',
      'Sleeping',
      'Designing',
      'Coding',
      'Cycling',
      'Running',
    ],
    datasets: [
      {
        label: 'My First dataset',
        backgroundColor: 'rgba(220, 220, 220, 0.2)',
        borderColor: 'rgba(220, 220, 220, 1)',
        pointBackgroundColor: 'rgba(220, 220, 220, 1)',
        pointBorderColor: '#fff',
        pointHighlightFill: '#fff',
        pointHighlightStroke: 'rgba(220, 220, 220, 1)',
        data: [65, 59, 90, 81, 56, 55, 40],
      },
      {
        label: 'My Second dataset',
        backgroundColor: 'rgba(151, 187, 205, 0.2)',
        borderColor: 'rgba(151, 187, 205, 1)',
        pointBackgroundColor: 'rgba(151, 187, 205, 1)',
        pointBorderColor: '#fff',
        pointHighlightFill: '#fff',
        pointHighlightStroke: 'rgba(151, 187, 205, 1)',
        data: [28, 48, 40, 19, 96, 27, 100],
      },
    ],
  }}/>
        </div>

        <p className='font-light mt-16'>Questions per difficulty level</p>
         </div>
        </div>
        
        <div className='flex justify-between gap-4'>

          <div className='flex flex-col bg-white px-6 py-4 shadow-lg rounded-md gap-4 w-[50%]'>
    
            <CChart
  type="line" 
  data={{
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "My First dataset",
        backgroundColor: "rgba(220, 220, 220, 0.2)",
        borderColor: "rgba(220, 220, 220, 1)",
        pointBackgroundColor: "rgba(220, 220, 220, 1)",
        pointBorderColor: "#fff",
        data: [40, 20, 12, 39, 10, 40, 39, 80, 40]
      },
      {
        label: "My Second dataset",
        backgroundColor: "rgba(151, 187, 205, 0.2)",
        borderColor: "rgba(151, 187, 205, 1)",
        pointBackgroundColor: "#076BF7",
        pointBorderColor: "#fff",
        data: [50, 12, 28, 29, 7, 25, 12, 70, 60]
      },
    ],
  }}/>

  <p className='font-light'>Number of questions per topic area per year</p>
          </div>

          <div className='flex flex-col bg-white px-6 py-4 shadow-lg rounded-md gap-4 w-[50%]'>
    
            <CChart
  type="bar" 
  data={{
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'GitHub Commits',
        backgroundColor: '#076BF7',
        data: [40, 20, 12, 39, 10, 40, 39, 80, 40],
      },
    ],
  }}/>

  <p className='font-light'>Number of questions per topic area per year per paper</p>
          </div>
        </div>
        </div>
  )
}

export default SubjectTab
