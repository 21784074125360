import React, { useState } from 'react'
import { LoginBg } from '../assets'
import { UsersInput } from '../components'
import { FaEnvelope, FaLock } from 'react-icons/fa'
import { FcGoogle } from 'react-icons/fc'
import { motion } from 'framer-motion'
import { buttonClick } from '../animations';
import { NavLink } from 'react-router-dom';
import { alertInfo } from '../context/actions/alertActions'
import { useDispatch } from 'react-redux'
import {useNavigate} from 'react-router-dom'
import axios from 'axios';
import { toast } from 'react-hot-toast'


const AddNewUsersTab = () => {

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [accessLevel, setAccessLevel] = useState("");
  const [password, setPassword] = useState("");
  
  
  const navigate = useNavigate();

  const signUp = async () => {
    const data = {
        first_name: firstName,
        last_name: lastName,
        username: userName,
        phone_number: phoneNumber,
        email: userEmail,
        access_level: accessLevel,
        password: password
    }

    try {
        const response = await fetch(`https://62.12.113.73/api.aaadev.com/api/create-account`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        if(response.status === 201){
            toast.success("Account created successfully! Proceed to Login");
            setFirstName("");
            setLastName("");
            setUserName("");
            setPhoneNumber("");
            setUserEmail("");
            setAccessLevel("");
            setPassword("");
            // navigate("/login", {replace : true})
            
            // window.location.reload('https://62.12.113.73/dashboard/exams');
            
        }
    }catch (error) {
        console.log(error)
    }
   
}

  return (
    <div className='flex flex-col bg-white w-full gap-6 px-4 md:px-12 py-8'>
            <UsersInput
                placeHolder="First Name"
                inputState={firstName}
                inputStateFunc={setFirstName}
                type="text"
            />

            <UsersInput
                placeHolder="Last Name"
                inputState={lastName}
                inputStateFunc={setLastName}
                type="text"
            />

            <UsersInput
                placeHolder="Username"
                inputState={userName}
                inputStateFunc={setUserName}
                type="text"
            />

            <UsersInput
                placeHolder="Phone Number"
                inputState={phoneNumber}
                inputStateFunc={setPhoneNumber}
                type="number"
            />

            <UsersInput
                placeHolder="Enter your email"
                inputState={userEmail}
                inputStateFunc={setUserEmail}
                type="email"
            />

            <UsersInput
                placeHolder="Access Level"
                inputState={accessLevel}
                inputStateFunc={setAccessLevel}
                type="text"
            />

            <UsersInput
                placeHolder="Enter Password"
                inputState={password}
                inputStateFunc={setPassword}
                type="password"
            />

            {/* button */}
        
            <motion.button 
            {...buttonClick} 
            onClick={() => signUp()}
            className='w-full px-4 py-2 bg-blue-500  rounded-md cursor-pointer text-white capitalize hover:bg-red-600 transition-all duration-150'
            >Register</motion.button>

        </div>
  )
}

export default AddNewUsersTab
