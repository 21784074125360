import React, { useState } from 'react'
import GradeTab from './GradeTab'
import SubjectTab from './SubjectTab'
import DifficultTab from './DifficultTab'
import UsersTab from './UsersTab'
import TrafficTab from './TrafficTab'

const Tabs = () => {

    const [activeIndex, setActiveIndex] = useState(1);
    const handleClick = (index) => setActiveIndex(index);
    const checkActive = (index, className) => activeIndex === index ? className : "";

    const tabs = [
        {
            id: 1,
            title: 'Grade',
            content: <GradeTab/>,
        },

        {
            id: 2,
            title: 'Subject Area',
            content: <SubjectTab/>,
        },

        {
            id: 3,
            title: 'Difficulty Level',
            content: <DifficultTab/>,
        },

        {
            id: 4,
            title: 'Users',
            content: <UsersTab/>,
        },

        {
            id: 5,
            title: 'Traffic',
            content: <TrafficTab/>,
        },
    ]
  return (
    <div className='flex flex-col px-8'>

    <div className='flex justify-between bg-white shadow-lg px-6 py-4 rounded-lg'>
    {tabs.map(tabs => (
        <button 
        key={tabs.id}
        onClick={() => handleClick(tabs.id)}
        className={`${checkActive(
            tabs.id,
            "border-b-4 border-blue-500 text-blue-500"
        )} hover:text-blue-500`}
        >
        {tabs.title}</button>))}
    </div>

    <div>
    {tabs.map(tabs => (
        <div
        key={tabs.id}
        className={`flex flex-col py-10 panel ${checkActive(
            tabs.id,
            "active"
        )}`}
        >{tabs.content}</div>))}
    </div>
        
    </div>
    
  )
}

export default Tabs
