import React from 'react'
import { RolesTable } from '../components'

const RolesTab = () => {
  return (
    <div className='flex flex-col bg-white shadow-md rounded-lg px-2 py-6'>
      
    <RolesTable/>

    </div>
  )
}

export default RolesTab
