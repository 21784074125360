import React from 'react'
import { PermissionsTable } from '../components'

const PermissionsTab = () => {
  return (
    <div className='flex flex-col bg-white shadow-md rounded-lg px-2 py-6'>
      <PermissionsTable/>
    </div>
  )
}

export default PermissionsTab
