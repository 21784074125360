import React, { useState } from 'react';
import { buttonClick } from '../animations'
import {motion} from 'framer-motion'
import { FaBan, FaEdit, FaSave, FaTrash, FaCloudUploadAlt, FaChevronRight } from 'react-icons/fa'
import { fadeInOut } from '../animations'
import { DropDownComponent, DropDownComponent2, TopicDropDown, DropDownComponent3, SubjectInput, QuestionInput, QuestionIdInput, QuestionNumberInput, SymbolsInput, TotalMarksInput, VideoUrlInput, AnswerMethodInput, AnswerInput, RecommendedTimeInput, DropDownSubject } from '../components'
import { Screenshot } from '../assets'
import Spinner from './Spinner';
import { Progress } from '../components/';
import { toast } from 'react-hot-toast'
import {useNavigate} from 'react-router-dom'

const NewExamTab = () => {

  const [isLoading, setisLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState(null);
  const [selectedImageSolution, setSelectedImageSolution] = useState(null);

    const [selectedYear, setSelectedYear] = useState("Select Year");
    const [selectedPaper, setSelectedPaper] = useState("Select Paper")
    const [topicSelected, setTopicSelected] = useState("Select Topic")
    const [curriculumSelected, setCurriculumSelected] = useState("IEB");

    const [isId, setIsId] = useState("");
    const [subjectSelected, setSubjectSelected] = useState("Mathematics");
    const [isQuestion, setIsQuestion] = useState("");
    const [isDifficulty, setIsDifficulty] = useState("");
    const [isQuestionNumber, setIsQuestionNumber] = useState("");
    const [isSymbol, setIsSymbol] = useState("");
    const [isTotalMarks, setIsTotalMarks] = useState("");
    const [isVideoURL, setIsVideoURL] = useState("");
    const [isMethodText, setIsMethodText] = useState("");
    const [isAnswer, setIsAnswer] = useState("");
    const [isTime, setIsTime] = useState("");

    const navigate = useNavigate();

    const approveQuestion = async() => {
   
      const data = {
        question_id: isId,
        subject_id: subjectSelected,
        curriculum: curriculumSelected,
        exam_year: selectedYear,
        exam_paper: selectedPaper,
        topic_id: 1,
        difficulty: 1,
        question_number: isQuestionNumber,
        question: isQuestion,
        question_img: selectedImages,
        total_marks: isTotalMarks,
        answer_video_url: isVideoURL,
        solution: selectedImageSolution,
        final_answer: isAnswer,
        recommended_time: isTime
      }
   
    try {
        const response = await fetch(`https://62.12.113.73/api.aaadev.com/api/add-question`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
        console.log(data)
        if(response.status === 201){
          toast.success("Question submitted successfully");
          setSubjectSelected("Mathematics");
          setCurriculumSelected('IEB');
          setSelectedYear('Select Year');
          setSelectedPaper('Select Paper');
          setTopicSelected('Select Topic');
          setIsQuestionNumber('');
          setSelectedImages('');
          setIsTime('')
          setIsTotalMarks('');
          setIsVideoURL('');
          setSelectedImageSolution('')
          // window.location='https://62.12.113.73/dashboard/exams';
          
      }
    } catch (error) {
        console.log(error);
    }

    };

    const onSelectFile = (e) => {
      setisLoading(true);
    const imageFile = e.target.files;
    const imageFileArray = Array.from(imageFile);

    const imagesArray = imageFileArray.map((file) => {
      return URL.createObjectURL(file);
    });

    // setSelectedImages((previousImages) => previousImages.concat(imagesArray));
    setSelectedImages(imagesArray);

    }

    const onSelectSolution = (e) => {
      setisLoading(true);
    const imageFile = e.target.files;
    const imageFileArray = Array.from(imageFile);

    const imagesArray = imageFileArray.map((file) => {
      return URL.createObjectURL(file);
    });

    setSelectedImageSolution(imagesArray);

    }
  
    return (
      <div className='flex flex-col mt-4 gap-4'>
  
        {/* <div className='flex items-center gap-4'>
          <p>Question ID</p>
          <QuestionIdInput 
            type="number"
            inputState={isId}
            inputStateFunc={setIsId}
          />
        </div> */}
  
          <div className='flex flex-col bg-white shadow-lg rounded-md px-12 py-4 gap-4'>
  
          <div className='flex gap-16'>
          <p className='mt-2'>Subject</p>
          <div className='flex flex-col'>
          <DropDownSubject subjectSelected={subjectSelected} setSubjectSelected={setSubjectSelected}/>
          </div>
        </div>
  
        <div className='flex gap-9'>
          <p className='mt-2'>Curriculum</p>
          <div className='flex flex-col'>
          <DropDownComponent3 curriculumSelected={curriculumSelected} setCurriculumSelected={setCurriculumSelected}/>
          </div>
        </div>
  
        <div className='flex gap-10'>
          <p className='mt-2'>Exam Year</p>
          <div className='flex flex-col'>
          <DropDownComponent selectedYear={selectedYear} setSelectedYear={setSelectedYear}/>
          </div>
        </div>
  
        <div className='flex gap-8'>
          <p className='mt-2'>Exam Paper</p>
          <div className='flex flex-col'>
          <DropDownComponent2 selectedPaper={selectedPaper} setSelectedPaper={setSelectedPaper}/>
          </div>
        </div>
  
          </div>
  
          <div className='flex flex-col bg-white shadow-lg rounded-md px-12 py-4 gap-4'>
  
          <div className='flex gap-4'>
          <p className='mt-2'>Topic</p>
          <div className='flex flex-col'>
          <TopicDropDown topicSelected={topicSelected} setTopicSelected={setTopicSelected}/>
          </div>
        </div>
  
      <div className='font-semibold'>Choose Difficulty</div>
  
        <div className='flex gap-8'>
              <div className='flex items-center gap-4'>
                  <input type='checkbox' name={setIsDifficulty} value={isDifficulty}/>
                  <p className='font-light text-sm'>Easy</p>
              </div>
  
              <div className='flex items-center gap-4'>
                  <input type='checkbox' name={setIsDifficulty} value={isDifficulty}/>
                  <p className='font-light text-sm'>Moderate</p>
              </div>
  
              <div className='flex items-center gap-4'>
                  <input type='checkbox' name={setIsDifficulty} value={isDifficulty}/>
                  <p className='font-light text-sm'>Difficult</p>
              </div>
              </div>
  
        <div className='flex items-center gap-4'>
          <p>Question Number</p>
          <QuestionNumberInput 
            type="text"
            inputState={isQuestionNumber}
            inputStateFunc={setIsQuestionNumber}
          />
        </div>
  
        <p>Question</p>
  
        {/* <QuestionInput
          inputState={isQuestion}
          inputStateFunc={setIsQuestion}
        /> */}
      <div className='flex-1 bg-card backdrop-blur-md rounded-md border-2 border-dotted border-gray-300 cursor-pointer'>

      <div className='relative w-full h-full overflow-hidden rounded-md'>
                    {
                      selectedImages && selectedImages.map((image, index) => {
                        return (
                          <div key={image} className='px-6 py-4'>
                          <motion.img
                              src={image}
                              className='w-full h-full object-cover py-4 rounded-lg'
                            />
                            <motion.button
                              {...buttonClick}
                              className='flex items-center gap-4 bg-red-500 px-4 py-2 rounded-lg'
                              onClick={() => setSelectedImages(selectedImages.filter((e) => e !== image)) }
                              >
                                <p>Delete</p>
                                <FaTrash className='-rotate-0'/>
                            </motion.button>
                          </div>
                          
                        )
                      })
                    }
                  </div>

      <label>
                    <div className='flex flex-col items-center justify-center h-full w-full cursor-pointer'>
                      <div className='flex flex-col items-center cursor-pointer py-6'>
                      <p className='font-bold text-2xl'>
                        <FaCloudUploadAlt className='-rotate-0'/>
                      </p>
                        <p className='text-md text-textColor'>
                          Click to upload an image
                        </p>
                      </div>
                    </div>
                    <input
                      type="file"
                      name='images'
                      multiple
                      accept='image/*'
                      onChange={onSelectFile}
                      className='w-0 h-0'
                    />
                  </label>

      
      </div>
      
      <div className='flex items-center gap-7'>
          <p>Recommended Time</p>
          <RecommendedTimeInput 
            type="number"
            inputState={isTime}
            inputStateFunc={setIsTime}
          />
        </div>
  

        <div className='flex items-center gap-7'>
          <p>Total Marks</p>
          <TotalMarksInput 
            type="number"
            inputState={isTotalMarks}
            inputStateFunc={setIsTotalMarks}
          />
        </div>
          </div>
        
  
        <div className='flex flex-col bg-white shadow-lg rounded-md px-12 py-4 gap-4'>
          <p>Answer Video URL</p>
          <VideoUrlInput 
            type="text"
            inputState={isVideoURL}
            inputStateFunc={setIsVideoURL}
          />
  
      <div className='flex w-full h-300 mt-4'>
          <img src={Screenshot} alt='' className='w-full h-250 object-cover rounded-md'/>
        </div>
  
        <p>Preview</p>
  
        <p>Solution</p>
        <div className='flex-1 bg-card backdrop-blur-md rounded-md border-2 border-dotted border-gray-300 cursor-pointer'>

      <div className='relative w-full h-full overflow-hidden rounded-md'>
                    {
                      selectedImageSolution && selectedImageSolution.map((image, index) => {
                        return (
                          <div key={image} className='px-6 py-4'>
                          <motion.img
                              src={image}
                              className='w-full h-full object-cover py-4 rounded-lg'
                            />
                            <motion.button
                              {...buttonClick}
                              className='flex items-center gap-4 bg-red-500 px-4 py-2 rounded-lg'
                              onClick={() => setSelectedImageSolution(selectedImageSolution.filter((e) => e !== image)) }
                              >
                                <p>Delete</p>
                                <FaTrash className='-rotate-0'/>
                            </motion.button>
                          </div>
                          
                        )
                      })
                    }
                  </div>

      <label>
                    <div className='flex flex-col items-center justify-center h-full w-full cursor-pointer'>
                      <div className='flex flex-col items-center cursor-pointer py-6'>
                      <p className='font-bold text-2xl'>
                        <FaCloudUploadAlt className='-rotate-0'/>
                      </p>
                        <p className='text-md text-textColor'>
                          Click to upload an image
                        </p>
                      </div>
                    </div>
                    <input
                      type="file"
                      name='images'
                      multiple
                      accept='image/*'
                      onChange={onSelectSolution}
                      className='w-0 h-0'
                    />
                  </label>

      
      </div>
  
      {/* <p>Answer</p>
      <AnswerInput 
        type="number"
        inputState={isAnswer}
        inputStateFunc={setIsAnswer}
      /> */}
        </div>
  
      <div className='flex justify-end gap-8'>
      <button 
      onClick={() => approveQuestion()}
      className='flex bg-blue-500 px-4 py-2 rounded-md items-center gap-2'>
      <p className='text-white'>Next</p>
      <FaChevronRight size={16} color='white'/>
      </button>
  
      <button className='flex bg-red-500 px-4 py-2 rounded-md items-center gap-2'>
      <FaBan size={16} color='white'/>
      <p className='text-white'>Cancel</p>
      </button>
        
      </div>
      </div>
    )
  }

export default NewExamTab
