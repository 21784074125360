export const isActiveStyles = 
"text-lg text-blue-500 font-semibold hover-text-blue-500 px-4 py-2 duration-100 transition-all ease-in-out";

export const isNotActiveStyles =
"text-lg text-textColor hover:text-blue-500 duration-100 transition-all ease-in-out px-4 py-2"

export const isActiveStylesDash = 
"text-md text-blue-500 font-semibold hover-text-blue-500 px-4 py-2 duration-100 transition-all ease-in-out";

export const isNotActiveStylesDash = 
"text-md text-textColor hover:text-blue-500 duration-100 transition-all ease-in-out px-4 py-2"