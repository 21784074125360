import React from 'react'
import { Logo } from '../assets'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { isActiveStylesDash, isNotActiveStylesDash } from '../utils/styles'

const DBLeftSection = () => {
  return (
    <div className='h-full py-6 flex flex-col bg-white backdrop-blur-md shadow-md min-w-210 w-275 gap-3'>
        <NavLink to={'/'} className='flex items-center gap-2 px-2 py-4'>
        <p className='font-semibold text-xl text-[#c23b21]'><span className='text-[#ffd301]'>Funda</span>Manje</p>
        <p>|</p>
        <p className="text-textColor text-xs">Making Studying Smarter</p>
        </NavLink>
    <hr/>
    <ul className='flex flex-col gap-1'>
    <NavLink className={({isActive}) => isActive ? `${isActiveStylesDash} px-4 py-2 border-l-8 border-blue-500` : isNotActiveStylesDash} to={"/dashboard/home"}>Home</NavLink>
            <NavLink className={({isActive}) => isActive ? `${isActiveStylesDash} px-4 py-2 border-l-8 border-blue-500` : isNotActiveStylesDash} to={"/dashboard/exams"}>Exams</NavLink>
            <NavLink className={({isActive}) => isActive ? `${isActiveStylesDash} px-4 py-2 border-l-8 border-blue-500` : isNotActiveStylesDash} to={"/dashboard/analytics"}>Analytics</NavLink>
            <NavLink className={({isActive}) => isActive ? `${isActiveStylesDash} px-4 py-2 border-l-8 border-blue-500` : isNotActiveStylesDash} to={"/dashboard/userManagement"}>User Management</NavLink>
            <NavLink className={({isActive}) => isActive ? `${isActiveStylesDash} px-4 py-2 border-l-8 border-blue-500` : isNotActiveStylesDash} to={"/dashboard/studentManagement"}>Student Management</NavLink>
    </ul>

    </div>
  )
}

export default DBLeftSection