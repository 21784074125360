import React, { useState } from 'react';
import { buttonClick } from '../animations'
import {motion} from 'framer-motion'
import { FaBan, FaEdit, FaSave, FaUpload, FaCloudUploadAlt, FaChevronRight, FaTrashAlt } from 'react-icons/fa'
import { fadeInOut } from '../animations'
import { ApprovedListTable, DropDownComponent, DropDownComponent2, DropDownComponent3, DropDownSubject } from '../components'
import { ThemeProvider, createTheme } from '@mui/material';
import MaterialTable from 'material-table';

const ApprovedList = () => {

    const [selectedYear, setSelectedYear] = useState("Select Year");
    const [selectedPaper, setSelectedPaper] = useState("Select Paper")
    const [topicSelected, setTopicSelected] = useState("Select Topic")
    const [curriculumSelected, setCurriculumSelected] = useState("IEB");
    const [subjectSelected, setSubjectSelected] = useState("Mathematics");


  return (
    <div className='flex flex-col mt-4 gap-4'>
      
      <div className='flex flex-col bg-white shadow-lg rounded-md px-12 py-4 gap-4'>
  
      <div className='flex gap-16'>
          <p className='mt-2'>Subject</p>
          <div className='flex flex-col'>
          <DropDownSubject subjectSelected={subjectSelected} setSubjectSelected={setSubjectSelected}/>
          </div>
        </div>

<div className='flex gap-9'>
  <p className='mt-2'>Curriculum</p>
  <div className='flex flex-col'>
  <DropDownComponent3 curriculumSelected={curriculumSelected} setCurriculumSelected={setCurriculumSelected}/>
  </div>
</div>

<div className='flex gap-10'>
  <p className='mt-2'>Exam Year</p>
  <div className='flex flex-col'>
  <DropDownComponent selectedYear={selectedYear} setSelectedYear={setSelectedYear}/>
  </div>
</div>

<div className='flex gap-8'>
  <p className='mt-2'>Exam Paper</p>
  <div className='flex flex-col'>
  <DropDownComponent2 selectedPaper={selectedPaper} setSelectedPaper={setSelectedPaper}/>
  </div>
</div>

<div className='flex justify-end gap-8 items-center'>
<button className='flex bg-red-500 px-4 py-2 rounded-md items-center gap-2'>
      <p className='text-white'>Delete Full Paper</p>
      <FaTrashAlt size={16} color='white'/>
      </button>
</div>

  </div>

  <div className='flex flex-col bg-white shadow-md rounded-lg px-2 py-6'>
      <ApprovedListTable/>
    </div>

    <div className='flex justify-end gap-8 items-center'>
      <button className='flex bg-blue-500 px-4 py-2 rounded-md items-center gap-2'>
      <p className='text-white'>Upload</p>
      <FaUpload size={16} color='white'/>
      </button>
  
      <button className='flex bg-red-500 px-4 py-2 rounded-md items-center gap-2'>
      <FaBan size={16} color='white'/>
      <p className='text-white'>Cancel</p>
      </button>
        
      </div>
    </div>
  )
}

export default ApprovedList;
