import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa'


const DropDownSubject = ({subjectSelected, setSubjectSelected}) => {
    const [isActive, setIsActive] = useState(false);
    const subjects = [
        "Mathematics",
    ]
  return (
    <div className='font-medium w-40'>
    <div onClick={(e) => setIsActive(!isActive)} className='bg-gray-200 w-full p-2 flex items-center justify-between rounded'>
      <p className='font-light text-sm'>{subjectSelected}</p>
      <FaChevronDown size={14} />
    </div>

      {isActive && (
          <ul className='bg-gray-200 mt-2 overflow-y-auto max-h-40'>
          {subjects.map(subjects => (
              <li onClick={(e) => {
                  setSubjectSelected(subjects);
                  setIsActive(false);
                  }}
                  className='p-2 text-sm hover:bg-blue-500 hover:text-white'>{subjects}</li>
          ))}
    </ul>
      )}
  </div>
  )
}

export default DropDownSubject;
