import React from 'react'
import { FaEdit, FaTrash} from 'react-icons/fa';
import { UsersTable } from '../components'

const AuthorisedUsersTab = (props) => {

  console.log(props.tab)
  return (
    <div className='flex flex-col bg-white shadow-md rounded-lg py-6'>
      
      <UsersTable tab={props.tab} />

    </div>
  )
}

export default AuthorisedUsersTab
