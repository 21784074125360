import React from 'react';
import {motion} from 'framer-motion'
import { fadeInOut } from '../animations'

const UsersInput = ({placeHolder, icon, inputState, inputStateFunc, type }) => {

  return (
    <motion.div 
    {...fadeInOut}
    className={`bg-lightOverlay backdrop-blur-md px-4 py-2 border-2 rounded-lg`}>
        {icon}
        <input 
        type={type}
        placeholder={placeHolder}
        value={inputState}
        onChange={(e) => inputStateFunc(e.target.value)}
        className='w-full h-full bg-transparent text-headingColor text-md font-semibold border-none outline-none [-moz-appearance:_textfield] [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none'
         />
    </motion.div>
  )
}

export default UsersInput