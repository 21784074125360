import React from 'react'
import DBHeader from './DBHeader'
import { Route, Routes } from 'react-router-dom'
import DBHome from './DBHome'
import DBExams from './DBExams'
import Analytics from './Analytics'
import UserManagement from './UserManagement'
import StudentManagement from './StudentManagement'

const DBRightSection = () => {
  return (
    <div className='flex flex-col px-10 py-12 flex-1 h-full'>
    <DBHeader/>
    <div className='flex flex-col flex-1 overflow-y-scroll'>
      <Routes>
      <Route path="/home" element={<DBHome/>} />
      <Route path="/exams" element={<DBExams/>} />
      <Route path="/analytics" element={<Analytics/>} />
      <Route path="/userManagement" element={<UserManagement/>} />
      <Route path="/studentManagement" element={<StudentManagement/>} />
      </Routes>
    </div>
    </div>
  )
}

export default DBRightSection