import React, { useState } from 'react';
import { buttonClick } from '../animations'
import {motion} from 'framer-motion'
import { FaBan, FaEdit, FaSave, FaUpload, FaCloudUploadAlt, FaChevronRight } from 'react-icons/fa'
import { fadeInOut } from '../animations'
import { DropDownComponent, DropDownComponent2, DropDownComponent3, DropDownSubject, ReviewListTable } from '../components'
import { ThemeProvider, createTheme } from '@mui/material';
import MaterialTable from 'material-table';

const ReviewList = () => {

    const [selectedYear, setSelectedYear] = useState("Select Year");
    const [selectedPaper, setSelectedPaper] = useState("Select Paper")
    const [subjectSelected, setSubjectSelected] = useState("Mathematics")
    const [curriculumSelected, setCurriculumSelected] = useState("IEB");


  return (
    <div className='flex flex-col mt-4 gap-4'>
      
      <div className='flex flex-col bg-white shadow-lg rounded-md px-12 py-4 gap-4'>
  
  {/* <div className='flex items-center gap-4'>
  <p>Subject</p>
  <motion.div 
{...fadeInOut}
className={`flex items-center justify-center gap-2 bg-lightOverlay backdrop-blur-md w-52 px-4 py-2 border-2 rounded-lg`}>
  <input 
  type="text"
  className='w-full h-full bg-transparent text-headingColor text-sm font-semibold border-none outline-none [-moz-appearance:_textfield] [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none'
   />
</motion.div>
</div> */}

<div className='flex gap-16'>
  <p className='mt-2'>Subject</p>
  <div className='flex flex-col'>
  <DropDownSubject subjectSelected={subjectSelected} setSubjectSelected={setSubjectSelected}/>
  </div>
</div>


<div className='flex gap-9'>
  <p className='mt-2'>Curriculum</p>
  <div className='flex flex-col'>
  <DropDownComponent3 curriculumSelected={curriculumSelected} setCurriculumSelected={setCurriculumSelected}/>
  </div>
</div>

<div className='flex gap-10'>
  <p className='mt-2'>Exam Year</p>
  <div className='flex flex-col'>
  <DropDownComponent selectedYear={selectedYear} setSelectedYear={setSelectedYear}/>
  </div>
</div>

<div className='flex gap-8'>
  <p className='mt-2'>Exam Paper</p>
  <div className='flex flex-col'>
  <DropDownComponent2 selectedPaper={selectedPaper} setSelectedPaper={setSelectedPaper}/>
  </div>
</div>

  </div>

  <div className='flex flex-col bg-white shadow-md rounded-lg px-2 py-6'>
      <ReviewListTable />
    </div>

    <div className='flex justify-end gap-8'>
      <button className='flex bg-blue-500 px-4 py-2 rounded-md items-center gap-2'>
      <p className='text-white'>Next</p>
      <FaChevronRight size={16} color='white'/>
      </button>
  
      <button className='flex bg-red-500 px-4 py-2 rounded-md items-center gap-2'>
      <FaBan size={16} color='white'/>
      <p className='text-white'>Cancel</p>
      </button>
        
      </div>
    </div>
  )
}

export default ReviewList
