import React, { useState } from 'react'
import AddNewUsersTab from './AddNewUsersTab'
import AuthorisedUsersTab from './AuthorisedUsersTab'
import RolesTab from './RolesTab'
import PermissionsTab from './PermissionsTab'

const UserManagementTabs = () => {

    const [activeIndex, setActiveIndex] = useState(1);
    const handleClick = (index) => setActiveIndex(index);
    const checkActive = (index, className) => activeIndex === index ? className : "";

    const tabs = [
        {
            id: 1,
            title: 'Authorised Users',
            content: <AuthorisedUsersTab tab={activeIndex}/>,
        },

        {
            id: 2,
            title: 'Add New User',
            content: <AddNewUsersTab/>,
        },

        {
            id: 3,
            title: 'Roles',
            content: <RolesTab/>,
        },

        {
            id: 4,
            title: 'Permissions',
            content: <PermissionsTab/>,
        },

    ]
  return (
    <div className='flex flex-col px-8'>

    <div className='flex justify-between bg-white shadow-lg px-6 py-4 rounded-lg'>
    {tabs.map(tabs => (
        <button 
        key={tabs.id}
        onClick={() => handleClick(tabs.id)}
        className={`${checkActive(
            tabs.id,
            "border-b-4 border-blue-500 text-blue-500"
        )} hover:text-blue-500`}
        >
        {tabs.title}</button>))}
    </div>

    <div>
    {tabs.map(tabs => (
        <div
        key={tabs.id}
        className={`flex flex-col py-10 panel ${checkActive(
            tabs.id,
            "active"
        )}`}
        >{tabs.content}</div>))}
    </div>
        
    </div>
    
  )
}

export default UserManagementTabs
