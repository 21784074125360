import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa'

const DropDownComponent = ({selectedYear, setSelectedYear}) => {
    const [isActive, setIsActive] = useState(false);
    const year = ["2018", "2019", "2020", "2021", "2022", "2023"];
  return (
    <div className='font-medium w-40'>
      <div onClick={(e) => setIsActive(!isActive)} className='bg-gray-200 w-full p-2 flex items-center justify-between rounded'>
        <p className='font-light text-sm'>{selectedYear}</p>
        <FaChevronDown size={14} />
      </div>

        {isActive && (
            <ul className='bg-gray-200 mt-2 overflow-y-auto max-h-40'>
            {year.map(year => (
                <li onClick={(e) => {
                    setSelectedYear(year);
                    setIsActive(false);
                    }}
                    className='p-2 text-sm hover:bg-blue-500 hover:text-white'>{year}</li>
            ))}
      </ul>
        )}
    </div>
  )
}

export default DropDownComponent
