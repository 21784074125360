import React, { useReducer, createContext } from "react";

const initialState = {
    activeIndex: 1,
};
export const GlobalContext = createContext(initialState);

const reducer = (state, action) => {
  switch (action.type) {
      case "activeIndex":
          return { ...state, activeIndex: action.payload };
    case "clear":
      return {
        ...state,
          activeIndex: 1,
      };
    default:
      return state;
  }
};

export const GlobalProvider = (props) => {
  const [globalState, globalDispatch] = useReducer(reducer, initialState);
  return (
      <GlobalContext.Provider value={{ globalState, globalDispatch }}>
      {props.children}
    </GlobalContext.Provider>
  );
};
