import React from 'react'
import { FaNewspaper, FaComments, FaQuestion } from 'react-icons/fa'
import { CChart } from '@coreui/react-chartjs'

const DBHome = () => {
  return (
    <div className='flex flex-col mt-12 gap-4'>

      <div className="flex items-center w-full justify-between gap-2">

        <div className="flex flex-col bg-white px-6 py-6 rounded-xl shadow-md">
        <div className="flex items-center gap-16">

        <div className="flex flex-col gap-2">
        <p className="text-blue-500 font-bold text-3xl">1,500</p>
          <p className="text-textColor text-xs">Number of Questions</p>
        </div>
        
        <FaQuestion size="50" color="gray"/>
        </div>
        </div>

        <div className="flex flex-col bg-white px-6 py-6 rounded-xl shadow-md">
        <div className="flex items-center gap-16">

        <div className="flex flex-col gap-2">
        <p className="text-blue-500 font-bold text-3xl">500</p>
          <p className="text-textColor text-xs">Number of PastPapers</p>
        </div>

        <FaNewspaper size="50" color="gray"/>
        </div>
        </div>

        <div className="flex flex-col bg-white px-6 py-6 rounded-xl shadow-md">
        <div className="flex items-center gap-16">

        <div className="flex flex-col gap-2">
        <p className="text-blue-500 font-bold text-3xl">20</p>
          <p className="text-textColor text-xs">Number of Topics</p>
        </div>
        <FaComments size="50" color="gray"/>
        </div>
        </div>
      </div>
      
        <div className='flex gap-4'>

          <div className='flex flex-col bg-white px-6 py-4 shadow-lg rounded-md'>
            <CChart
  type="polarArea"
  data={{
    labels: ['Red', 'Green', 'Yellow', 'Grey', 'Blue'],
    datasets: [
      {
        data: [11, 16, 7, 3, 14],
        backgroundColor: ['#FF6384', '#4BC0C0', '#FFCE56', '#E7E9ED', '#36A2EB'],
      },
    ],
  }}/>
          </div>

          <div className='flex-1 flex-col bg-white px-6 py-4 shadow-lg rounded-md'>
          <CChart
  type="bar"
  data={{
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Number of Questions',
        backgroundColor: ' #076BF7',
        data: [40, 20, 12, 39, 10, 40, 39, 80, 40],
      },
    ],
  }}/>
          </div>
        </div>
        
      <div className='flex flex-col bg-white shadow-lg rounded-md px-12 py-4 mb-20'>

        <div className='flex flex-col'>
          <h1 className='font-semibold text-blue-500'>Recently uploaded PastPapers</h1>
        </div>

        <div className='flex justify-between mt-6'>

        <div className='flex flex-col items-center'>
        <h1 className='font-semibold'>Year</h1>
        <p className='text-textColor'>2019</p>
        <p className='text-textColor'>2020</p>
        <p className='text-textColor'>2021</p>
        </div>
          
        <div className='flex flex-col items-center'>
        <h1 className='font-semibold'>Paper 1</h1>
        <p className='text-textColor'>10</p>
        <p className='text-textColor'>20</p>
        <p className='text-textColor'>30</p>
        </div>

        <div className='flex flex-col items-center mb-10'>
        <h1 className='font-semibold'>Paper 2</h1>
        <p className='text-textColor'>10</p>
        <p className='text-textColor'>20</p>
        <p className='text-textColor'>30</p>
        </div>
        </div>
      </div>
        </div>
      
  )
}

export default DBHome