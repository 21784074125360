import React from 'react';
import { ExamTabs } from '../components'


const DBExams = () => {

  return (
    <div className='flex flex-col'>
      <ExamTabs />
    </div>
  )
}

export default DBExams