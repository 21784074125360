import React from 'react'
import { CChart } from '@coreui/react-chartjs'


const UsersTab = () => {
  return (
    <div className='flex flex-col gap-4'>
      
        <div className='flex justify-between gap-4'>

          <div className='flex flex-col bg-white px-6 py-4 shadow-lg rounded-md gap-4 w-[50%]'>
    
            <CChart
  type="line" 
  data={{
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "My First dataset",
        backgroundColor: "rgba(220, 220, 220, 0.2)",
        borderColor: "rgba(220, 220, 220, 1)",
        pointBackgroundColor: "rgba(220, 220, 220, 1)",
        pointBorderColor: "#fff",
        data: [40, 20, 12, 39, 10, 40, 39, 80, 40]
      },
      {
        label: "My Second dataset",
        backgroundColor: "rgba(151, 187, 205, 0.2)",
        borderColor: "rgba(151, 187, 205, 1)",
        pointBackgroundColor: "#076BF7",
        pointBorderColor: "#fff",
        data: [50, 12, 28, 29, 7, 25, 12, 70, 60]
      },
    ],
  }}/>

  <p className='font-light'>Number of users per subject</p>
          </div>

          <div className='flex flex-col bg-white px-6 py-4 shadow-lg rounded-md gap-4 w-[50%]'>
    
            <CChart
  type="bar" 
  data={{
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'GitHub Commits',
        backgroundColor: '#076BF7',
        data: [40, 20, 12, 39, 10, 40, 39, 80, 40],
      },
    ],
  }}/>

  <p className='font-light'>Number of users subscribed after trial period Male vs Female</p>
          </div>
        </div>


        <div className='flex justify-between gap-4'>
        <div className='flex-col bg-white px-6 py-4 shadow-lg rounded-md w-[50%]'>

        <div className='w-72 h-64'>
        <CChart
        className='w-full h-full'
        type="doughnut"
        data={{
        labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
        datasets: [
        {
        backgroundColor: ['#41B883', '#E46651', '#076BF7', '#DD1B16'],
        data: [40, 20, 80, 10],
        },
        ],
        }}/>
        </div>


        <p className='font-light mt-16'>Users AI marking Vs Manual</p>
         </div>

         <div className='flex-1 flex-col bg-white px-6 py-4 shadow-lg rounded-md'>

        <div className='w-72 h-64'>
        <CChart 
  type="radar"
  data={{
    labels: [
      'Eating',
      'Drinking',
      'Sleeping',
      'Designing',
      'Coding',
      'Cycling',
      'Running',
    ],
    datasets: [
      {
        label: 'My First dataset',
        backgroundColor: 'rgba(220, 220, 220, 0.2)',
        borderColor: 'rgba(220, 220, 220, 1)',
        pointBackgroundColor: 'rgba(220, 220, 220, 1)',
        pointBorderColor: '#fff',
        pointHighlightFill: '#fff',
        pointHighlightStroke: 'rgba(220, 220, 220, 1)',
        data: [65, 59, 90, 81, 56, 55, 40],
      },
      {
        label: 'My Second dataset',
        backgroundColor: 'rgba(151, 187, 205, 0.2)',
        borderColor: 'rgba(151, 187, 205, 1)',
        pointBackgroundColor: 'rgba(151, 187, 205, 1)',
        pointBorderColor: '#fff',
        pointHighlightFill: '#fff',
        pointHighlightStroke: 'rgba(151, 187, 205, 1)',
        data: [28, 48, 40, 19, 96, 27, 100],
      },
    ],
  }}/>
        </div>

        <p className='font-light mt-32'>Users Test Mode comparison Full Exam Vs Custom test mode</p>
         </div>
        </div>
        
        <div className='flex justify-between gap-4'>

          <div className='flex flex-col bg-white px-6 py-4 shadow-lg rounded-md gap-4 w-[50%]'>
    
            <CChart
  type="line" 
  data={{
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "My First dataset",
        backgroundColor: "rgba(220, 220, 220, 0.2)",
        borderColor: "rgba(220, 220, 220, 1)",
        pointBackgroundColor: "rgba(220, 220, 220, 1)",
        pointBorderColor: "#fff",
        data: [40, 20, 12, 39, 10, 40, 39, 80, 40]
      },
      {
        label: "My Second dataset",
        backgroundColor: "rgba(151, 187, 205, 0.2)",
        borderColor: "rgba(151, 187, 205, 1)",
        pointBackgroundColor: "#076BF7",
        pointBorderColor: "#fff",
        data: [50, 12, 28, 29, 7, 25, 12, 70, 60]
      },
    ],
  }}/>

  <p className='font-light'>Most Requested Difficult level comparison</p>
          </div>

          <div className='flex flex-col bg-white px-6 py-4 shadow-lg rounded-md gap-4 w-[50%]'>
    
            <CChart
  type="bar" 
  data={{
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'GitHub Commits',
        backgroundColor: '#076BF7',
        data: [40, 20, 12, 39, 10, 40, 39, 80, 40],
      },
    ],
  }}/>

  <p className='font-light'>Top 10 questions answered with video</p>
          </div>
        </div>

        <div className='flex flex-col bg-white px-6 py-8 shadow-lg rounded-md gap-4'>

        <div className='flex'>

        <div className='flex flex-col gap-4'>
        <div className='flex items-center font-light gap-4 px-8'>
                <input type='checkbox' name='answers' value="1"/>
                <p>Average Number of Questions Completed</p>
            </div>

            <div className='flex items-center font-light gap-4 px-8'>
                <input type='checkbox' name='answers' value="1"/>
                <p>Most Wronged/Flagged Questions</p>
            </div>

            <div className='flex items-center font-light gap-4 px-8'>
                <input type='checkbox' name='answers' value="1"/>
                <p>Most Wronged/Flagged Questions per topic</p>
            </div>

            <div className='flex items-center font-light gap-4 px-8'>
                <input type='checkbox' name='answers' value="1"/>
                <p>Percentage of Custom Test creations</p>
            </div>

            <div className='flex items-center font-light gap-4 px-8'>
                <input type='checkbox' name='answers' value="1"/>
                <p>Average answering pace per question</p>
            </div>

            <div className='flex items-center font-light gap-4 px-8'>
                <input type='checkbox' name='answers' value="1"/>
                <p>Average User time per Question ID</p>
            </div>

            <div className='flex mt-6 px-8'>
            <button className='flex bg-blue-500 px-4 py-2 rounded-md items-center'>
            <p className='text-white'>Generate Excel</p>
            </button>
            </div>
        </div>

        <div className='w-[1px] h-60 bg-gray-300 rounded-md'></div>

        <div className='flex flex-col px-8 gap-14'>
          <div className='flex items-center gap-4'>
          <p className='font-light'>Average Results in Custom Mode</p>
          <div className='flex items-center justify-center bg-white w-28 h-10 rounded-sm border-2 border-gray-300'>
            <p>200</p>
          </div>
          </div>

          <div className='flex items-center gap-9'>
          <p className='font-light'>Average Results in Exam Mode</p>
          <div className='flex items-center justify-center bg-white w-28 h-10 rounded-sm border-2 border-gray-300'>
          <p>200</p>
          </div>
          </div>
          
        </div>
        
        </div>
            
            
            
        </div>
        </div>
  )
}

export default UsersTab
