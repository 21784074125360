import React from 'react'
import { FaNewspaper, FaComments, FaQuestion } from 'react-icons/fa'
import { CChart } from '@coreui/react-chartjs'

const GradeTab = () => {
  return (
    <div className='flex flex-col gap-4'>
      
        <div className='flex gap-4'>

          <div className='flex flex-col bg-white px-6 py-4 shadow-lg rounded-md gap-4 w-[50%]'>
    
            <CChart
  type="line" 
  data={{
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "My First dataset",
        backgroundColor: "rgba(220, 220, 220, 0.2)",
        borderColor: "rgba(220, 220, 220, 1)",
        pointBackgroundColor: "rgba(220, 220, 220, 1)",
        pointBorderColor: "#fff",
        data: [40, 20, 12, 39, 10, 40, 39, 80, 40]
      },
      {
        label: "My Second dataset",
        backgroundColor: "rgba(151, 187, 205, 0.2)",
        borderColor: "rgba(151, 187, 205, 1)",
        pointBackgroundColor: "#076BF7",
        pointBorderColor: "#fff",
        data: [50, 12, 28, 29, 7, 25, 12, 70, 60]
      },
    ],
  }}/>

  <p className='font-light'>Number of Paper 1 and 2 per IEB & NSC</p>
          </div>

          <div className='flex flex-col bg-white px-6 py-4 shadow-lg rounded-md gap-4 w-[50%]'>
    
            <CChart
  type="bar" 
  data={{
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'GitHub Commits',
        backgroundColor: '#076BF7',
        data: [40, 20, 12, 39, 10, 40, 39, 80, 40],
      },
    ],
  }}/>

  <p className='font-light'>Number of IEB & NSC paper 1 and paper 2 per subject</p>
          </div>
        </div>
        
        <div className='flex-col bg-white px-6 py-4 shadow-lg rounded-md w-[50%] gap-4'>
        <CChart
  type="doughnut"
  data={{
    labels: ['VueJs', 'EmberJs', 'ReactJs', 'AngularJs'],
    datasets: [
      {
        backgroundColor: ['#41B883', '#E46651', '#076BF7', '#DD1B16'],
        data: [40, 20, 80, 10],
      },
    ],
  }}/>

<p className='font-light'>Number of Paper 1 and 2 per IEB & NSC</p>
          </div>
        </div>
  )
}

export default GradeTab
