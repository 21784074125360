import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa'


const DropDownComponent3 = ({curriculumSelected, setCurriculumSelected}) => {
    const [isActive, setIsActive] = useState(false);
    const curriculum = [
        "IEB",
        "NSC"
    ]
  return (
    <div className='font-medium w-40'>
    <div onClick={(e) => setIsActive(!isActive)} className='bg-gray-200 w-full p-2 flex items-center justify-between rounded'>
      <p className='font-light text-sm'>{curriculumSelected}</p>
      <FaChevronDown size={14} />
    </div>

      {isActive && (
          <ul className='bg-gray-200 mt-2 overflow-y-auto max-h-40'>
          {curriculum.map(curriculum => (
              <li onClick={(e) => {
                  setCurriculumSelected(curriculum);
                  setIsActive(false);
                  }}
                  className='p-2 text-sm hover:bg-blue-500 hover:text-white'>{curriculum}</li>
          ))}
    </ul>
      )}
  </div>
  )
}

export default DropDownComponent3
