import React, { useState } from 'react';
import {motion} from 'framer-motion'
import { fadeInOut } from '../animations'

const LoginInput = ({placeHolder, icon, inputState, inputStateFunc, type, isSignUp}) => {

const [isFocus, setIsFocus] = useState(false);

  return (
    <motion.div 
    {...fadeInOut}
    className={`flex items-center justify-center gap-2 bg-lightOverlay backdrop-blur-md w-full px-4 py-2 border-2 rounded-2xl`}>
        {icon}
        <input 
        type={type}
        placeholder={placeHolder}
        value={inputState}
        onChange={(e) => inputStateFunc(e.target.value)}
        className='w-full h-full bg-transparent text-headingColor text-sm font-semibold border-none outline-none'
         />
    </motion.div>
  )
}

export default LoginInput