import React from 'react'
import { Tabs } from '../components'

const Analytics = () => {
  return (
    <div className='flex flex-col'>
      <Tabs />
      {/* <TabRoutes/> */}
    </div>
  )
}

export default Analytics