import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa'

const DropDownComponent2 = ({selectedPaper, setSelectedPaper}) => {
    const [isActive, setIsActive] = useState(false);
    const paper = ["1", "2"];
  return (
    <div className='font-medium w-40'>
      <div onClick={(e) => setIsActive(!isActive)} className='bg-gray-200 w-full p-2 flex items-center justify-between rounded'>
        <p className='font-light text-sm'>{selectedPaper}</p>
        <FaChevronDown size={14} />
      </div>

        {isActive && (
            <ul className='bg-gray-200 mt-2 overflow-y-auto max-h-40'>
            {paper.map(paper => (
                <li onClick={(e) => {
                    setSelectedPaper(paper);
                    setIsActive(false);
                    }}
                    className='p-2 text-sm hover:bg-blue-500 hover:text-white'>{paper}</li>
            ))}
      </ul>
        )}
    </div>
  )
}
export default DropDownComponent2
