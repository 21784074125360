import React, { useState } from 'react';
import { buttonClick } from '../animations'
import {motion} from 'framer-motion'
import { FaBan, FaSave, FaCloudUploadAlt, FaChevronRight, FaTrash } from 'react-icons/fa'
import { fadeInOut } from '../animations'
import { DropDownComponent, DropDownComponent2, TopicDropDown, DropDownComponent3, DropDownSubject, RecommendedTimeInput, TotalMarksInput, QuestionNumberInput, VideoUrlInput } from '../components'
import { Screenshot } from '../assets'


const ReviewTab = () => {

    const [selectedYear, setSelectedYear] = useState("Select Year");
    const [selectedPaper, setSelectedPaper] = useState("Select Paper")
    const [topicSelected, setTopicSelected] = useState("Select Topic")
    const [curriculumSelected, setCurriculumSelected] = useState("IEB")
    const [subjectSelected, setSubjectSelected] = useState("Mathematics");

    const [selectedImages, setSelectedImages] = useState(null);
    const [selectedImageSolution, setSelectedImageSolution] = useState(null);
    const [isDifficulty, setIsDifficulty] = useState("");
    const [isQuestionNumber, setIsQuestionNumber] = useState("");
    const [isTime, setIsTime] = useState("");
    const [isTotalMarks, setIsTotalMarks] = useState("");
    const [isVideoURL, setIsVideoURL] = useState("");

    const onSelectFile = (e) => {
    const imageFile = e.target.files;
    const imageFileArray = Array.from(imageFile);

    const imagesArray = imageFileArray.map((file) => {
      return URL.createObjectURL(file);
    });

    // setSelectedImages((previousImages) => previousImages.concat(imagesArray));
    setSelectedImages(imagesArray);

    }

    const onSelectSolution = (e) => {
    const imageFile = e.target.files;
    const imageFileArray = Array.from(imageFile);

    const imagesArray = imageFileArray.map((file) => {
      return URL.createObjectURL(file);
    });

    setSelectedImageSolution(imagesArray);

    }
  
    return (
      <div className='flex flex-col mt-4 gap-4'>
  
        {/* <div className='flex items-center gap-4'>
          <p>Question ID</p>
          <motion.div 
      {...fadeInOut}
      className={`flex items-center justify-center gap-2 bg-lightOverlay backdrop-blur-md w-40 px-4 py-2 border-2 rounded-lg`}>
          <input 
          type="number"
          className='w-full h-full bg-transparent text-headingColor text-sm font-semibold border-none outline-none [-moz-appearance:_textfield] [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none'
           />
      </motion.div>
        </div> */}
  
                <div className='flex flex-col bg-white shadow-lg rounded-md px-12 py-4 gap-4'>
          
          <div className='flex gap-16'>
          <p className='mt-2'>Subject</p>
          <div className='flex flex-col'>
          <DropDownSubject subjectSelected={subjectSelected} setSubjectSelected={setSubjectSelected}/>
          </div>
        </div>

        <div className='flex gap-9'>
          <p className='mt-2'>Curriculum</p>
          <div className='flex flex-col'>
          <DropDownComponent3 curriculumSelected={curriculumSelected} setCurriculumSelected={setCurriculumSelected}/>
          </div>
        </div>

        <div className='flex gap-10'>
          <p className='mt-2'>Exam Year</p>
          <div className='flex flex-col'>
          <DropDownComponent selectedYear={selectedYear} setSelectedYear={setSelectedYear}/>
          </div>
        </div>

        <div className='flex gap-8'>
          <p className='mt-2'>Exam Paper</p>
          <div className='flex flex-col'>
          <DropDownComponent2 selectedPaper={selectedPaper} setSelectedPaper={setSelectedPaper}/>
          </div>
        </div>

          </div>
  
          <div className='flex flex-col bg-white shadow-lg rounded-md px-12 py-4 gap-4'>
  
          <div className='flex gap-4'>
          <p className='mt-2'>Topic</p>
          <div className='flex flex-col'>
          <TopicDropDown topicSelected={topicSelected} setTopicSelected={setTopicSelected}/>
          </div>
        </div>
  
      <div className='font-semibold'>Choose Difficulty</div>
  
        <div className='flex gap-8'>
              <div className='flex items-center gap-4'>
                  <input type='checkbox' name={setIsDifficulty} value={isDifficulty}/>
                  <p className='font-light text-sm'>Easy</p>
              </div>
  
              <div className='flex items-center gap-4'>
                  <input type='checkbox' name={setIsDifficulty} value={isDifficulty}/>
                  <p className='font-light text-sm'>Moderate</p>
              </div>
  
              <div className='flex items-center gap-4'>
                  <input type='checkbox' name={setIsDifficulty} value={isDifficulty}/>
                  <p className='font-light text-sm'>Difficult</p>
              </div>
              </div>
  
        <div className='flex items-center gap-4'>
          <p>Question Number</p>
          <QuestionNumberInput 
            type="number"
            inputState={isQuestionNumber}
            inputStateFunc={setIsQuestionNumber}
          />
        </div>
  
        <p>Question</p>
  
        {/* <QuestionInput
          inputState={isQuestion}
          inputStateFunc={setIsQuestion}
        /> */}
      <div className='flex-1 bg-card backdrop-blur-md rounded-md border-2 border-dotted border-gray-300 cursor-pointer'>

      <div className='relative w-full h-full overflow-hidden rounded-md'>
                    {
                      selectedImages && selectedImages.map((image, index) => {
                        return (
                          <div key={image} className='px-6 py-4'>
                          <motion.img
                              src={image}
                              className='w-full h-full object-cover py-4 rounded-lg'
                            />
                            <motion.button
                              {...buttonClick}
                              className='flex items-center gap-4 bg-red-500 px-4 py-2 rounded-lg'
                              onClick={() => setSelectedImages(selectedImages.filter((e) => e !== image)) }
                              >
                                <p>Delete</p>
                                <FaTrash className='-rotate-0'/>
                            </motion.button>
                          </div>
                          
                        )
                      })
                    }
                  </div>

      <label>
                    <div className='flex flex-col items-center justify-center h-full w-full cursor-pointer'>
                      <div className='flex flex-col items-center cursor-pointer py-6'>
                      <p className='font-bold text-2xl'>
                        <FaCloudUploadAlt className='-rotate-0'/>
                      </p>
                        <p className='text-md text-textColor'>
                          Click to upload an image
                        </p>
                      </div>
                    </div>
                    <input
                      type="file"
                      name='images'
                      multiple
                      accept='image/*'
                      onChange={onSelectFile}
                      className='w-0 h-0'
                    />
                  </label>

      
      </div>
      
      <div className='flex items-center gap-7'>
          <p>Recommended Time</p>
          <RecommendedTimeInput 
            type="number"
            inputState={isTime}
            inputStateFunc={setIsTime}
          />
        </div>
  

        <div className='flex items-center gap-7'>
          <p>Total Marks</p>
          <TotalMarksInput 
            type="number"
            inputState={isTotalMarks}
            inputStateFunc={setIsTotalMarks}
          />
        </div>
          </div>
        
  
          <div className='flex flex-col bg-white shadow-lg rounded-md px-12 py-4 gap-4'>
          <p>Answer Video URL</p>
          <VideoUrlInput 
            type="text"
            inputState={isVideoURL}
            inputStateFunc={setIsVideoURL}
          />
  
      <div className='flex w-full h-300 mt-4'>
          <img src={Screenshot} alt='' className='w-full h-250 object-cover rounded-md'/>
        </div>
  
        <p>Preview</p>
  
        <p>Solution</p>
        <div className='flex-1 bg-card backdrop-blur-md rounded-md border-2 border-dotted border-gray-300 cursor-pointer'>

      <div className='relative w-full h-full overflow-hidden rounded-md'>
                    {
                      selectedImageSolution && selectedImageSolution.map((image, index) => {
                        return (
                          <div key={image} className='px-6 py-4'>
                          <motion.img
                              src={image}
                              className='w-full h-full object-cover py-4 rounded-lg'
                            />
                            <motion.button
                              {...buttonClick}
                              className='flex items-center gap-4 bg-red-500 px-4 py-2 rounded-lg'
                              onClick={() => setSelectedImageSolution(selectedImageSolution.filter((e) => e !== image)) }
                              >
                                <p>Delete</p>
                                <FaTrash className='-rotate-0'/>
                            </motion.button>
                          </div>
                          
                        )
                      })
                    }
                  </div>

      <label>
                    <div className='flex flex-col items-center justify-center h-full w-full cursor-pointer'>
                      <div className='flex flex-col items-center cursor-pointer py-6'>
                      <p className='font-bold text-2xl'>
                        <FaCloudUploadAlt className='-rotate-0'/>
                      </p>
                        <p className='text-md text-textColor'>
                          Click to upload an image
                        </p>
                      </div>
                    </div>
                    <input
                      type="file"
                      name='images'
                      multiple
                      accept='image/*'
                      onChange={onSelectSolution}
                      className='w-0 h-0'
                    />
                  </label>

      
      </div>
  
      {/* <p>Answer</p>
      <AnswerInput 
        type="number"
        inputState={isAnswer}
        inputStateFunc={setIsAnswer}
      /> */}
        </div>
  
      <div className='flex justify-end gap-8'>
      <button className='flex bg-blue-500 px-4 py-2 rounded-md items-center gap-2'>
      <FaSave size={16} color='white'/>
      <p className='text-white'>Save</p>
      </button>
  
      <button className='flex bg-green-500 px-4 py-2 rounded-md items-center gap-2'>
      <p className='text-white'>Next Question</p>
      <FaChevronRight size={16} color='white'/>
      </button>

      <button className='flex bg-red-500 px-4 py-2 rounded-md items-center gap-2'>
      <FaBan size={16} color='white'/>
      <p className='text-white'>Cancel</p>
      </button>
        
      </div>
      </div>
    )
  }

export default ReviewTab
