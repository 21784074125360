import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa'

const TopicDropDown = ({topicSelected, setTopicSelected}) => {
    const [isActive, setIsActive] = useState(false);
    const topics = [
      "Functions and Interverse Functions",
      "Exponential and Logarithmic Functions",
      "Finance, Growth and Decay",
      "Algebraic Equations",
      "Trigonometry: Compound and Double Angle Identities",
      "Trigonometry: Problem Solving in Two and Three Dimensions",
      "Polynomials",
      "Differential Calculus",
      "Analytical Geometry",
      "Euclidean Geometry",
      "Statistics",
      "Counting Principles and Probability",
    ];
  return (
    <div className='font-medium w-96'>
      <div onClick={(e) => setIsActive(!isActive)} className='bg-gray-200 w-full p-2 flex items-center justify-between rounded'>
        <p className='font-light text-sm'>{topicSelected}</p>
        <FaChevronDown size={14} />
      </div>

        {isActive && (
            <ul className='bg-gray-200 mt-2 overflow-y-auto max-h-40'>
            {topics.map(topics => (
                <li onClick={(e) => {
                    setTopicSelected(topics);
                    setIsActive(false);
                    }}
                    className='p-2 text-sm font-light hover:bg-blue-500 hover:text-white'>{topics}</li>
            ))}
      </ul>
        )}
    </div>
  )
}

export default TopicDropDown
